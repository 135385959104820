<template>
  <div class="rn_my_wrap">
    <div class="rn_my_tit_2">
      내가 쓴 댓글 보기<router-link to="/mypage/my_info"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_tab_2">
      <div :class="{ on: desc }">
        <router-link to="" @click.native="getCommentList(true, 'desc')"
          >최신순</router-link
        >
      </div>
      <div :class="{ on: old }">
        <router-link to="" @click.native="getCommentList(true, 'old')"
          >오래된순</router-link
        >
      </div>
      <div :class="{ on: reply }">
        <router-link to="" @click.native="getCommentList(true, 'reply')"
          >답글수순</router-link
        >
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_15"></div>
    <!---->
    <div class="rn_my_box_7" v-if="listArray.length > 0">
      <div
        class="box"
        v-for="(list, index) in listArray"
        :key="`${list.seq}${index}`"
      >
        <div class="top">
          <div class="left">
            {{ list.reg_dt.substr(2, 8).replace(/-/g, ".") }}
            <span class="reply_count">답글 수({{ list.reply_cnt }})</span>
          </div>
          <div class="right">
            <router-link
              to=""
              class="view-link"
              :id="`modi_btn_${list.seq}`"
              @click.native="modifiy(true, list.seq)"
              >수정</router-link
            >

            <router-link
              to=""
              class="view-link"
              :id="`del_btn_${list.seq}`"
              @click.native="deleteReply(list.seq)"
              >삭제</router-link
            >
            <router-link
              to=""
              class="save_link"
              :id="`save_btn_${list.seq}`"
              @click.native="updateReply(list.seq)"
              >저장</router-link
            >
            <router-link
              to=""
              class="save_link"
              :id="`cancel_btn_${list.seq}`"
              @click.native="modifiy(false, list.seq)"
              >취소</router-link
            >
          </div>
        </div>
        <div class="middle" :id="`view_${list.seq}`" v-html="list.content">
          {{ list.content }}
        </div>
        <div class="middle edit" :id="`edit_${list.seq}`">
          <textarea
            :value="textAreraBr(list.content)"
            :id="`textarea_${list.seq}`"
          ></textarea>
        </div>
        <div class="bottom">
          <h3 @click="movePage(index)">{{ list.title }}</h3>
          <ul>
            <li>
              <router-link
                to=""
                @click.native="
                  setWish({
                    wish_yn: list.wish_yn,
                    reviewer_seq: list.member_seq,
                    main_seq: list.main_menu_seq,
                    type: 'reviewer',
                    review_seq: null,
                  })
                "
              >
                <img
                  v-if="list.wish_yn == 'Y'"
                  src="@/assets/images/sub/like_on.png"
                  alt=""
                />
                <img
                  v-else
                  src="@/assets/images/sub/like_off.png"
                  alt=""
                /> </router-link
              >{{ list.reviewer_nick }}
            </li>
            <li>리뷰어 신뢰도 {{ rammFloat(list.trust_score) }}</li>
            <li>{{ list.reg_dt.substr(2, 8).replace(/-/g, ".") }}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="rn_my_box_7" v-else>
      <p :style="{ textAlign: 'center' }">작성한 댓글이 없습니다.</p>
    </div>
    <div class="rn_more" v-if="totalPage > page">
      <router-link to="" @click.native="getCommentList(false, sort)"
        >{{ limit }}개 더보기<img
          src="@/assets/images/sub/my_down_ico.png"
          alt=""
      /></router-link>
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: 0,
      sort: "desc",
      modify: false,
      sortName: {
        desc: true,
        old: false,
        reply: false,
      },
    };
  },
  created() {
    //초기화
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    //초기화
    this.$store.dispatch("mypage/clearRammList");

    this.getCommentList(true);
  },
  computed: {
    ...mapState("mypage", [
      "checkAuth",
      "totalPage",
      "listArray",
      "limit",
      "result",
      "msg",
    ]),
    desc: {
      get() {
        return this.sortName.desc;
      },
      set(desc) {
        this.sortName.desc = desc;
      },
    },
    old: {
      get() {
        return this.sortName.old;
      },
      set(old) {
        this.sortName.desc = old;
      },
    },
    reply: {
      get() {
        return this.sortName.reply;
      },
      set(reply) {
        this.sortName.desc = reply;
      },
    },
  },
  methods: {
    rammFloat(str) {
      return this.$rammFloat(str);
    },
    movePage(index) {
      const data = this.listArray[index];
      let url = "";
      if (parseInt(data.main_menu_seq) === 1) {
        url = `/review/goods_main/${data.category_code}?goods_seq=${data.goods_seq}&option=${data.option_category1}&option2=${data.option_category2}&option3=${data.option_category3}&seq=${data.review_seq}`;
      } else if (parseInt(data.main_menu_seq) === 3) {
        url = `/review/news_main/${data.option_category1}?category=${data.option_category2}&seq=${data.review_seq}`;
      } else {
        return false;
      }
      this.$router.push(url);
    },
    textAreraBr(str) {
      return str.replace(/<br ?\/?>/g, "\r");
    },
    modifiy(mode, seq) {
      if (mode) {
        // 글을 감싸고 있는 div 의 높이 구해오기
        let height = document.querySelector(`#view_${seq}`).scrollHeight;

        document.querySelector(`#view_${seq}`).style.display = "none";
        document.querySelector(`#modi_btn_${seq}`).style.display = "none";
        document.querySelector(`#del_btn_${seq}`).style.display = "none";

        //textarea에 높이 세팅
        document.querySelector(
          `#edit_${seq} textarea`
        ).style.height = `${height}px`;

        document.querySelector(`#edit_${seq}`).style.display = "block";
        document.querySelector(`#save_btn_${seq}`).style.display = "inline";
        document.querySelector(`#cancel_btn_${seq}`).style.display = "inline";
      } else {
        document.querySelector(`#textarea_${seq}`).value =
          document.querySelector(`#view_${seq}`).textContent;

        document.querySelector(`#edit_${seq}`).style.display = "none";
        document.querySelector(`#save_btn_${seq}`).style.display = "none";
        document.querySelector(`#cancel_btn_${seq}`).style.display = "none";

        document.querySelector(`#view_${seq}`).style.display = "block";
        document.querySelector(`#modi_btn_${seq}`).style.display = "inline";
        document.querySelector(`#del_btn_${seq}`).style.display = "inline";
      }
    },
    updateReply(seq) {
      const content = document.querySelector(`#textarea_${seq}`).value;
      if (content.replace(/\s/g, "").length <= 0) {
        this.$toast.default("댓글을 입력해주세요.");
        return false;
      }
      this.$confirm("댓글을 수정하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("mypage/updateReply", {
            content,
            seq,
          });
          this.$toast.default(this.msg);
          document.querySelector(`#edit_${seq}`).style.display = "none";
          document.querySelector(`#save_btn_${seq}`).style.display = "none";
          document.querySelector(`#cancel_btn_${seq}`).style.display = "none";

          document.querySelector(`#view_${seq}`).style.display = "block";
          document.querySelector(`#modi_btn_${seq}`).style.display = "inline";
          document.querySelector(`#del_btn_${seq}`).style.display = "inline";
        })
        .catch(() => {
          return false;
        });
    },
    deleteReply(seq) {
      this.$confirm("댓글을 삭제 하시겠습니까?")
        .then(async () => {
          await this.$store.dispatch("mypage/deleteRely", { seq });
          this.$toast.default(this.msg);
        })
        .catch(() => {
          return false;
        });
    },
    async getCommentList(reset = false, sort = "desc") {
      if (reset) {
        this.page = 0;
      }
      this.sort = sort;

      Object.keys(this.sortName).forEach((key) => {
        if (key === sort) {
          this.sortName[key] = true;
        } else {
          this.sortName[key] = false;
        }
      });

      this.$data[sort] = true;
      await this.$store.dispatch("mypage/getCommentList", {
        sort: this.sort,
        page: this.page,
        reset,
      });
      this.page++;
    },
    async setWish(params) {
      if (params.wish_yn === "Y") {
        params.wish_yn = "N";
      } else {
        params.wish_yn = "Y";
      }
      // console.log(params);
      await this.$store.dispatch("wish/updateWish", params);
      this.$toast.default(this.$store.state.wish.msg);
      if (this.$store.state.wish.result) {
        this.$store.dispatch("mypage/updateWishComment", params);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.middle {
  &.edit {
    display: none;
  }
}
.save_link {
  display: none;
}
.rn_my_box_7 .box .top .right a:nth-child(3) {
  margin-right: 5px;
}
.middle {
  textarea {
    width: 100%;
  }
}
.reply_count {
  margin-left: 5px;
  font-size: 11px;
}
</style>
